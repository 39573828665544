<template>
  <div class="template">
    <div class="template__content">
      <h1>Nos partenaires</h1>

      <div class="row">
        <div class="col-3">
          <div class="title" style="height: 60px">Les partenaires d'actions</div>

          <div class="card mr-2 p-4 info u-text-center">
            <a class="partenaire" href="https://www.reseau-eiffel.fr/" target="_blank">
              <span class="image"
                ><img src="@/assets/images/reseau-eiffel.png" alt="reseau effeil logo"
              /></span>
            </a>
          </div>

          <div class="card mr-2 p-4 info u-text-center">
            <a class="partenaire" href="http://www.tela13.org/" target="_blank">
              <span class="image"
                ><img src="@/assets/images/tela13.png" alt="reseau alpha logo"
              /></span>
            </a>
          </div>

          <div class="card mr-2 p-4 info u-text-center">
            <a
              class="partenaire"
              href="https://maisondesliensfamiliaux.fr/contacts/centre-social-toussarego/"
              target="_blank"
            >
              <span class="image"
                ><img src="@/assets/images/Toussarego.jpg" alt="reseau alpha logo"
              /></span>
            </a>
          </div>

          <div class="card mr-2 p-4 info u-text-center">
            <a
              class="partenaire"
              href="https://www.paris.fr/equipements/point-d-acces-au-droit-p-a-d-13e-3380"
              target="_blank"
            >
              <span class="image"
                ><img src="@/assets/images/pad13.png" alt="reseau alpha logo"
              /></span>
            </a>
          </div>

          <div class="card mr-2 p-4 info u-text-center">
            <a class="partenaire" href="http://www.casp.asso.fr/" target="_blank">
              <span class="image"><img src="@/assets/images/casp.png" alt="casp logo"/></span>
            </a>
          </div>
        </div>

        <div class="col-3">
          <div class="title" style="height: 60px">
            Les structures partenaires de la rentrée partagée du 13ème
          </div>

          <div class="card mr-2 p-4 info u-text-center">
            <a class="partenaire" href="https://centrealphachoisy.fr/" target="_blank">
              <span class="image"
                ><img src="@/assets/images/centrealphachoisy.png" alt="centre alpha choisy"
              /></span>
            </a>
          </div>

          <div class="card mr-2 p-4 info u-text-center">
            <a class="partenaire" href="http://fle-et-compagnie.org/" target="_blank">
              <span class="image"><img src="@/assets/images/flecie.png" alt="fle et cie"/></span>
            </a>
          </div>

          <div class="card mr-2 p-4 info u-text-center">
            <a
              class="partenaire"
              href="https://www.maisondesrefugies.paris/GERMAE_a272.html"
              target="_blank"
            >
              <span class="image"><img src="@/assets/images/germae.jpeg" alt="gerame"/></span>
            </a>
          </div>

          <div class="card mr-2 p-4 info u-text-center">
            <a class="partenaire" href="http://www.afif.fr" target="_blank">
              <span class="image"><img src="@/assets/images/afif.jpg" alt="afif"/></span>
            </a>
          </div>

          <div class="card mr-2 p-4 info u-text-center">
            <a class="partenaire" href="https://keurkamer.org/" target="_blank">
              <span class="image"><img src="@/assets/images/keurkamer.png" alt="keur kamer"/></span>
            </a>
          </div>

          <div class="card mr-2 p-4 info u-text-center">
            <a class="partenaire" href="https://www.croiseedeslangues.fr/" target="_blank">
              <span class="image"
                ><img src="@/assets/images/croisees_des_langues.png" alt="croisees des langues logo"
              /></span>
            </a>
          </div>

          <div class="card mr-2 p-4 info u-text-center">
            <a class="partenaire" href="https://www.reseau-eiffel.fr/" target="_blank">
              <span class="image"
                ><img src="@/assets/images/reseau-eiffel.png" alt="reseau effeil logo"
              /></span>
            </a>
          </div>

          <div class="card mr-2 p-4 info u-text-center">
            <a
              class="partenaire"
              href="https://www.immigration.interieur.gouv.fr/Info-ressources/Fonds-europeens/Les-fonds-europeens-periode-2014-2020/Le-Fonds-Asile-Migration-Integration-FAMI-et-le-Fonds-Securite-Interieure-FSI"
              target="_blank"
            >
              <span class="image"
                ><img src="@/assets/images/famifsi.jpg" alt="reseau effeil logo"
              /></span>
            </a>
          </div>
        </div>

        <div class="col-3">
          <div class="title" style="height: 60px">Les partenaires financiers</div>

          <div class="card mr-2 p-4 info u-text-center">
            <a class="partenaire" href="https://www.paris.fr/" target="_blank">
              <span class="image"
                ><img src="@/assets/images/mairieParieLogo.svg" alt="Mairie de Paris logo"
              /></span>
            </a>
          </div>

          <div class="card mr-2 p-4 info u-text-center">
            <a
              class="partenaire"
              href="https://www.prefectures-regions.gouv.fr/ile-de-france"
              target="_blank"
            >
              <span class="image"
                ><img src="@/assets/images/prefecture.jpg" alt="prefecture logo"
              /></span>
            </a>
          </div>
        </div>

        <div class="col-3">
          <div class="title" style="height: 60px">Les autres partenaires</div>

          <div class="card mr-2 p-4 info u-text-center">
            <a class="partenaire" href="https://www.reseau-alpha.org/" target="_blank">
              <span class="image"
                ><img src="@/assets/images/reseau-alpha.png" alt="reseau alpha logo"
              /></span>
            </a>
          </div>

          <div class="card mr-2 p-4 info u-text-center">
            <a class="partenaire" href="https://www.reseau-eiffel.fr/" target="_blank">
              <span class="image"
                ><img src="@/assets/images/vivreensemble.png" alt="vivre ensemble logo"
              /></span>
            </a>
          </div>

          <div class="card mr-2 p-4 info u-text-center">
            <a class="partenaire" href="https://www.maisondesrefugies.paris/" target="_blank">
              <span class="image"
                ><img src="@/assets/images/maisondesrefugies.png" alt="maison des refugies logo"
              /></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
